// export const ENDPOINT = 'https://today-i-learned.org:80';
// //export const ENDPOINT = 'http://localhost:80';
// export const THIS_URL = 'https%3A%2F%2Ftoday-i-learned.org';
// //export const THIS_URL = 'https%3A%2F%2Flocalhost';

// export const GH_APP_ID = '5c2258cb88831cea80c2';
// export const LI_APP_ID = '86v6z3n8v3ybvo';
// export const COG_APP_ID = '438vn2gdv98kv5bu1viqvqmgjc'; //prod
// //export const COG_APP_ID = '3u9578d8jvrnk2aa0erhnocd1n'; //local

// export const TWITTER_LINK = 'https://twitter.com/TodayIL38903307';
// export const GITHUB_LINK = 'https://github.com/AndrewBuntsev/til';



//export const ENDPOINT = 'https://today-i-learned.org:80';
export const ENDPOINT = 'https://dzw56frcba.execute-api.ap-southeast-2.amazonaws.com/prod';
//export const ENDPOINT = 'http://localhost:80';
//export const THIS_URL = 'https%3A%2F%2Ftoday-i-learned.org';
//export const THIS_URL = 'https%3A%2F%2Ftoday-i-learned.org';
//export const THIS_URL = 'https%3A%2F%2Flocalhost';
//export const THIS_URL = 'https%3A%2F%2Fd2tha6ht526vyi.cloudfront.net';
export const THIS_URL = 'https%3A%2F%2Ftoday-i-learned.org';

export const GH_APP_ID = '5c2258cb88831cea80c2';
export const LI_APP_ID = '869cisd2sxukdc';
//export const COG_APP_ID = '438vn2gdv98kv5bu1viqvqmgjc'; //prod
//export const COG_APP_ID = '3u9578d8jvrnk2aa0erhnocd1n'; //local
export const COG_APP_ID = '2dr4kjb7btvf0qqfv0vfkdajon'; //cloudfront

export const TWITTER_LINK = 'https://twitter.com/TodayIL38903307';
export const GITHUB_LINK = 'https://github.com/AndrewBuntsev/til';